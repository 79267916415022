import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`HTML is accessible by default. At least, if you use the right native elements. Very often, developers build
custom form controls to meet design needs – neglecting accessibility in the process.`}</p>
    <p>{`As I'll show in this post, modern browsers enable us to apply custom styling to most native form controls.
This way, we can create accessible web forms with a consistent look across all platforms.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAf/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdcyEaCcD//EABkQAQEAAwEAAAAAAAAAAAAAAAECAAMSFP/aAAgBAQABBQIL1nbM+mXKHjHXC//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPwFtf//EABYRAQEBAAAAAAAAAAAAAAAAAAAREv/aAAgBAgEBPwGVl//EAB4QAAICAAcAAAAAAAAAAAAAAAABEUECECExMlGB/9oACAEBAAY/Am7d7kqJ6K9ZpijLif/EABwQAAMAAQUAAAAAAAAAAAAAAAABESExQVFx4f/aAAgBAQABPyFdHGZmPBu0Jj2NskBrQEniE5pdQj//2gAMAwEAAgADAAAAEPc//8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/EBwl/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAERIVH/2gAIAQIBAT8QsljXT//EAB0QAQEAAgMAAwAAAAAAAAAAAAERADEhQWFRgZH/2gAIAQEAAT8QXbzpCF6dl8ym1I19L8XmHWFjCYkUfowsJxoGt2uAipLtGX8xKQvRn//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Brush and spatula on a brightly painted canvas",
          "title": "Brush and spatula on a brightly painted canvas",
          "src": "/static/04b9cc3391b17ea4910677c8e523c542/e5166/pexels-steve-johnson-paint-brush.jpg",
          "srcSet": ["/static/04b9cc3391b17ea4910677c8e523c542/f93b5/pexels-steve-johnson-paint-brush.jpg 300w", "/static/04b9cc3391b17ea4910677c8e523c542/b4294/pexels-steve-johnson-paint-brush.jpg 600w", "/static/04b9cc3391b17ea4910677c8e523c542/e5166/pexels-steve-johnson-paint-brush.jpg 1200w", "/static/04b9cc3391b17ea4910677c8e523c542/b17f8/pexels-steve-johnson-paint-brush.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Steve Johnson / pexels.com`}</em></p>
    <h2>{`When Design Needs wreck Accessibility`}</h2>
    <p>{`Native form controls like `}<InlineCode mdxType="InlineCode">{`input`}</InlineCode>{` or `}<InlineCode mdxType="InlineCode">{`select`}</InlineCode>{` are accessible
by default. They receive focus when keyboard users press the tab key. The enter and space key can be used
to interact with them, e.g., to mark a checkbox as checked. They communicate their role and current state
to screen readers.`}</p>
    <p>{`Sounds great! You would be mad not to use native form controls and, instead, build custom UI components
with non-semantic spaghetti code. Right? Well, the sad truth is: Using custom form controls is a common practice.
And most of the time they're not accessible. Let's look at an example for a custom select:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<div class="custom-select">
    <div class="select-trigger">
        <span>PlayStation 5</span>
        <div class="arrow"></div>
    </div>
    <div class="custom-options">
        <span class="option selected">
            PlayStation 5
        </span>
        <span class="option">Nintendo Switch</span>
        <span class="option">Xbox Series X</span>
    </div>
</div>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`By itself, this mess of `}<InlineCode mdxType="InlineCode">{`div`}</InlineCode>{` and `}<InlineCode mdxType="InlineCode">{`span`}</InlineCode>{` tags is not accessible
to keyboard and screen reader users. Yes, you can fix this with ARIA attributes and keydown event listeners.
But many developers are either not aware of this or simply don't care. What they care about is styling
the custom elements to meet the design needs of their clients.`}</p>
    <h2>{`Why Styling Native Form Controls can be challenging`}</h2>
    <p>{`Using CSS to style form controls has historically been difficult. Browsers apply their own default styling and
form controls vary greatly in how easy they are to customize with CSS. Fortunately, this is getting easier as
old browsers are retired (`}<a parentName="p" {...{
        "href": "https://death-to-ie11.com/"
      }}>{`death to IE11`}</a>{`) and modern browsers offer more
features to use.`}</p>
    <p>{`Mozilla has a great article on `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Learn/Forms/Styling_web_forms"
      }}>{`Styling web forms`}</a>{`,
which I highly recommend. They categorize native form controls into three groups: “The good”, “The bad”, and
“The ugly”. I mostly agree with them, except for considering checkboxes and radio buttons difficult to style.
This may have been true in the past, but modern CSS allows you to do whatever you want as I'll show you.`}</p>
    <h2>{`Styling Example 1: Radio Buttons`}</h2>
    <p>{`In order to style your radio buttons with CSS, you need to set the value of the `}<InlineCode mdxType="InlineCode">{`appearance`}</InlineCode>{`
property to `}<InlineCode mdxType="InlineCode">{`none`}</InlineCode>{`. This removes the default appearance applied by the browser and operating
system. Now you can get creative and even apply smooth CSS transitions if you like:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    box-sizing: border-box;
    position: relative;
    width: 1.4em;
    height: 1.4em;
    border: 0.15rem solid darkred;
    border-radius: 50%;
}

input[type="radio"]::before {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    width: 0.7em;
    height: 0.7em;
    border-radius: 50%;
    background-color: darkred;
    /* scale(0) hides the dot in unchecked state */
    transform: translate(-50%, -50%) scale(0);
    transform-origin: center;
    transition: all 0.3s ease-in;
}

input[type="radio"]:checked::before {
    transform: translate(-50%, -50%) scale(1);
    transition: all 0.3s cubic-bezier(0.25, 0.25, 0.56, 2);
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`In my example, I defined the unchecked state of the radio button to appear as a darkred circle.
With the help of the `}<InlineCode mdxType="InlineCode">{`::before`}</InlineCode>{` pseudo-element and the `}<InlineCode mdxType="InlineCode">{`:checked`}</InlineCode>{`
pseudo-class, a darkred dot appears inside the circle when the radio button is selected. Check out the
`}<a parentName="p" {...{
        "href": "https://codepen.io/alexlehner86/pen/mdqGLxZ"
      }}>{`demo on Codepen`}</a>{`.`}</p>
    <h2>{`Styling Example 2: Select`}</h2>
    <p>{`The `}<InlineCode mdxType="InlineCode">{`select`}</InlineCode>{` element belongs to the category “The ugly”. The problem with the
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Learn/Forms/Advanced_form_styling#what_can_be_done_about_the_ugly_elements"
      }}>{`form controls in this group`}</a>{`
is that they have very different default looks across browsers. You can style them to some degree with CSS,
but some parts of their internals are literally impossible to style.`}</p>
    <p>{`You can use the `}<InlineCode mdxType="InlineCode">{`appearance`}</InlineCode>{` property to override the default look of
the `}<InlineCode mdxType="InlineCode">{`select`}</InlineCode>{` element. But this will not affect the list of options that appears
when you click on the element. Styling the `}<InlineCode mdxType="InlineCode">{`option`}</InlineCode>{` element with CSS is highly
limited and only supported by some browsers.`}</p>
    <p>{`In my opinion, the styling options are sufficient to meet basic design needs (e.g. color and font). Take a look at
my `}<a parentName="p" {...{
        "href": "https://codepen.io/alexlehner86/pen/mdqGLxZ"
      }}>{`demo on Codepen`}</a>{` where I used the following styling:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`select {
    -webkit-appearance: none;
    appearance: none;
    box-sizing: border-box;
    background-color: white;
    background-image:
        url('/assets/custom_arrow_for_select.svg');
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    border: 0.25rem solid darkred;
    padding: 0.25em;
    /* Leave enough space for the arrow on the right */
    padding-right: 2em;
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Native HTML is always better`}</h2>
    <p>{`What I wanted to show with this post is: Using native HTML is always better than messing around
with custom UI components. Because keyboard and screen reader accessibility is already built in.
Therefore, I would always pick the native form control if it can be sufficiently styled with CSS.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      